<script setup>
import {ref} from "vue";
import StringUtils from "@/utils/StringUtils";
const wallet = ref(localStorage.getItem("kepler.manage.wallet"));
const strUtils = new StringUtils();
</script>

<template>
  <div class="navigate-tab">
    <div class="logo">
      <span>Kepler-合约管理系统</span>
    </div>
    <div class="link">
      <span>{{ strUtils.sliceString(wallet)}}</span>
    </div>
  </div>
</template>

<style scoped lang="less">
.navigate-tab {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: cornflowerblue;

  .logo {
    color: white;
    font-size: 16px;
    font-weight: 400;
  }

  .link {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    font-size: 14px;
    font-weight: 100;
  }

}
</style>