<template>
  <router-view/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import WalletUtils from "@/utils/WalletUtils";

const walletAddress = ref(undefined);

onMounted(async () => {
  const walletUtils = new WalletUtils();
  walletAddress.value = await walletUtils.getWalletAddress();
  if (walletAddress.value) {
    localStorage.setItem("kepler.manage.wallet", walletAddress.value);
  }
})
</script>

