<template>
  <div class="home">
    <div class="nav">
      <navigate-tab/>
    </div>
    <div class="main">
      <div class="sys-info">
        <span class="subtitle">系统功能</span>
        <van-divider :style="{borderColor:'#FFFFFF'}" dashed/>
        <div class="info-form">
          <van-cell-group inset :style="{margin:0}">
            <van-field
                label-width="7.2em"
                label-align="right"
                v-model="sysInfo.minStakeAmount"
                center
                clearable
                label="最小质押(KPL)"
                placeholder="请输入最小质押数量"
            >
              <template #button>
                <van-button size="small" type="primary" :loading="updateLoading" @click="handleUpdate(1)">修改
                </van-button>
              </template>
            </van-field>
            <van-field
                label-width="7.2em"
                label-align="right"
                v-model="sysInfo.interest"
                center
                clearable
                label="日收益率(%)"
                placeholder="请输入日收益率"
            >
              <template #button>
                <van-button size="small" type="primary" :loading="updateLoading" @click="handleUpdate(2)">修改
                </van-button>
              </template>
            </van-field>
            <van-field
                label-width="7.2em"
                label-align="right"
                v-model="sysInfo.stakePeriod"
                center
                clearable
                label="质押时长(秒)"
                placeholder="请输入质押时长"
            >
              <template #button>
                <van-button size="small" type="primary" :loading="updateLoading" @click="handleUpdate(3)">修改
                </van-button>
              </template>
            </van-field>
            <van-field
                label-width="7.2em"
                label-align="right"
                v-model="sysInfo.registrationRewardsAmount"
                center
                clearable
                label="注册奖励(KPL)"
                placeholder="请输入注册奖励"
            >
              <template #button>
                <van-button size="small" type="primary" :loading="updateLoading" @click="handleUpdate(4)">修改
                </van-button>
              </template>
            </van-field>
            <van-field
                label-width="7.2em"
                label-align="right"
                v-model="sysInfo.checkInRewardsAmount"
                center
                clearable
                label="签到奖励(KPL)"
                placeholder="请输入签到奖励"
            >
              <template #button>
                <van-button size="small" type="primary" :loading="updateLoading" @click="handleUpdate(5)">修改
                </van-button>
              </template>
            </van-field>
            <van-field
                label-width="7.2em"
                label-align="right"
                v-model="sysInfo.directlyRecommendRewardsRate"
                center
                clearable
                label="直堆奖励(%)"
                placeholder="请输入直推奖励"
            >
              <template #button>
                <van-button size="small" type="primary" :loading="updateLoading" @click="handleUpdate(6)">修改
                </van-button>
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <van-button
            type="primary"
            :loading="distributeProfitLoading"
            loading-text="发放中..."
            block
            @click="handleDistributeProfit">
          发放收益
        </van-button>
      </div>
      <div class="account-info">
        <span class="subtitle">账户查询</span>
        <van-divider :style="{borderColor:'#FFFFFF'}" dashed/>
        <div class="search-check">
          <van-field
              v-model="fieldValue"
              is-link
              readonly
              label="查询类型"
              placeholder="选择查询类型"
              @click="showPicker = true"
          />
          <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                :columns="columns"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
          </van-popup>
        </div>
        <div class="search-wallet">
          <van-cell-group inset :style="{margin:0}">
            <van-field
                v-model="wallet"
                center
                clearable
                placeholder="请输入钱包地址"
            >
              <template #button>
                <van-button
                    :loading="searchLoading"
                    loading-text="查询中..."
                    size="small"
                    type="primary"
                    @click="handleSearch">查询
                </van-button>
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div class="account-cell" v-show="searchType === 0">
          <van-cell-group inset :style="{margin:0}">
            <van-cell title="投资总额" :value="account.totalInvested.toFixed(2)"/>
            <van-cell title="团队业绩" :value="account.teamStakeAmount.toFixed(2)"/>
            <van-cell title="会员等级" :value="account.level"/>
            <van-cell title="直推数量" :value="account.directMemberCounts"/>
            <van-cell title="成员数量" :value="account.teamMemberCounts"/>
            <van-cell title="投资时间" :value="DateTimeUtils.formatTimestamp(account.startTime)"/>
          </van-cell-group>
        </div>
        <div class="account-cell" v-show="searchType === 1">
          <van-cell-group inset :style="{margin:0}">
            <van-cell title="可取签到收益" :value="rewards.checkInRewards.toFixed(2)"/>
            <van-cell title="已取签到收益" :value="rewards.extractedCheckInRewards.toFixed(2)"/>
            <van-cell title="可取团队收益" :value="rewards.teamRewards.toFixed(2)"/>
            <van-cell title="已取团队收益" :value="rewards.extractedTeamRewards.toFixed(2)"/>
            <van-cell title="可取推荐收益" :value="rewards.referRewards.toFixed(2)"/>
            <van-cell title="已取推荐收益" :value="rewards.extractedReferRewards.toFixed(2)"/>
            <van-cell title="可取质押收益" :value="rewards.stakingRewards.toFixed(2)"/>
            <van-cell title="已取质押收益" :value="rewards.extractedStakingRewards.toFixed(2)"/>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavigateTab from "@/components/NavigateTab.vue";
import {onMounted, ref} from "vue";
import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress
} from "@/assets/chain/contractDetails";
import {showFailToast, showSuccessToast, showToast} from "vant";
import {DateTimeUtils} from "@/utils/DateTimeUtils";


let stakingContractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
};
const sysInfo = ref({
  minStakeAmount: 0,
  interest: 0,
  stakePeriod: 0,
  registrationRewardsAmount: 0,
  checkInRewardsAmount: 0,
  directlyRecommendRewardsRate: 0
});
const getSystemBaseInfo = async () => {
  const minStakeAmount = await stakingContractInstance.methods.MIN_STAKE_AMOUNT().call();
  const interest = await stakingContractInstance.methods.INTEREST().call();
  const stakePeriod = await stakingContractInstance.methods.STAKE_PERIOD().call();
  const registrationRewardsAmount = await stakingContractInstance.methods.REGISTRATION_REWARDS_AMOUNT().call();
  const checkInRewardsAmount = await stakingContractInstance.methods.CHECK_IN_REWARDS_AMOUNT().call();
  const directlyRecommendRewardsRate = await stakingContractInstance.methods.DIRECTLY_RECOMMEND_REWARDS_RATE().call();

  sysInfo.value.minStakeAmount = Number(web3.utils.fromWei(minStakeAmount, 'ether'));
  sysInfo.value.interest = Number(interest);
  sysInfo.value.stakePeriod = Number(stakePeriod);
  sysInfo.value.registrationRewardsAmount = Number(web3.utils.fromWei(registrationRewardsAmount, 'ether'));
  sysInfo.value.checkInRewardsAmount = Number(web3.utils.fromWei(checkInRewardsAmount, 'ether'));
  sysInfo.value.directlyRecommendRewardsRate = Number(directlyRecommendRewardsRate);
};

const updateLoading = ref(false);
const ownerAddress = ref(undefined);
const handleUpdate = async (type) => {
  if (await checkOwner() === false) {
    showToast("只有管理员拥有修改权限");
    return;
  }
  try {
    updateLoading.value = true;
    if (type === 1) {
      await stakingContractInstance.methods.setMinStakeAmount(web3.utils.toWei(sysInfo.value.minStakeAmount, 'ether'))
          .send({from: ownerAddress.value})
    } else if (type === 2) {
      await stakingContractInstance.methods.setInterest(sysInfo.value.interest, 100).send({from: ownerAddress.value});
    } else if (type === 3) {
      await stakingContractInstance.methods.setStakePeriod(sysInfo.value.stakePeriod, 3600).send({from: ownerAddress.value});
    } else if (type === 4) {
      await stakingContractInstance.methods.setRegistrationRewardsAmount(web3.utils.toWei(sysInfo.value.registrationRewardsAmount, 'ether'))
          .send({from: ownerAddress.value});
    } else if (type === 5) {
      await stakingContractInstance.methods.setCheckInRewardsAmount(web3.utils.toWei(sysInfo.value.checkInRewardsAmount, 'ether'))
          .send({from: ownerAddress.value});
    } else if (type === 6) {
      await stakingContractInstance.methods.setDirectlyRecommendRewardsRate(sysInfo.value.directlyRecommendRewardsRate)
          .send({from: ownerAddress.value});
    }
    updateLoading.value = false;
    showSuccessToast("修改成功");
  } catch (error) {
    console.log(error);
    updateLoading.value = false;
    showFailToast("修改失败");
  }
}

const distributeProfitLoading = ref(false);
const handleDistributeProfit = async () => {
  if (await checkOwner() === false) {
    showToast("只有管理员拥有修改权限");
    return;
  }
  distributeProfitLoading.value = true;
  try {
    await stakingContractInstance.methods.distributeProfit().send({from: ownerAddress.value});
    distributeProfitLoading.value = false;
    showSuccessToast("执行成功");
  } catch (error) {
    console.log(error);
    distributeProfitLoading.value = false;
    showFailToast("执行失败");
  }
}

const columns = [
  {text: '账户信息', value: 0},
  {text: '收益信息', value: 1}
];

const showPicker = ref(false);
const fieldValue = ref('');
const searchType = ref(-1);
const onConfirm = ({selectedOptions}) => {
  showPicker.value = false;
  fieldValue.value = selectedOptions[0].text;
  searchType.value = selectedOptions[0].value;
};

const wallet = ref('')

const account = ref({
  referrer: '',
  totalInvested: 0,
  teamStakeAmount: 0,
  startTime: '',
  level: 0,
  isStake: false,
  isBound: false,
  subordinates: [],
  teamMemberCounts: 0,
  directMemberCounts: 0
})

const getAccountInfo = async () => {
  const res = await stakingContractInstance.methods.accounts(wallet.value).call();
  const teamMemberCounts = await stakingContractInstance.methods.teamMemberCounts(wallet.value).call();
  const directMemberCounts = await stakingContractInstance.methods.directMemberCounts(wallet.value).call();
  account.value.referrer = res.referrer;
  account.value.totalInvested = Number(web3.utils.fromWei(res.totalInvested, 'ether'));
  account.value.teamStakeAmount = Number(web3.utils.fromWei(res.teamStakeAmount, 'ether'));
  account.value.startTime = Number(res.startTime);
  account.value.level = Number(res.level);
  account.value.isStake = res.isStake;
  account.value.isBound = res.isBound;
  account.value.subordinates = res.subordinates;
  account.value.teamMemberCounts = Number(teamMemberCounts);
  account.value.directMemberCounts = Number(directMemberCounts);
}

const rewards = ref({
  checkInRewards: 0,
  extractedCheckInRewards: 0,
  teamRewards: 0,
  extractedTeamRewards: 0,
  referRewards: 0,
  extractedReferRewards: 0,
  stakingRewards: 0,
  extractedStakingRewards: 0,
  registrationRewards: 0
});

const getRewardsInfo = async () => {
  const res = await stakingContractInstance.methods.rewards(wallet.value).call();
  rewards.value.checkInRewards = Number(web3.utils.fromWei(res.checkInRewards, 'ether'));
  rewards.value.extractedCheckInRewards = Number(web3.utils.fromWei(res.extractedCheckInRewards, 'ether'));
  rewards.value.teamRewards = Number(web3.utils.fromWei(res.teamRewards, 'ether'));
  rewards.value.extractedTeamRewards = Number(web3.utils.fromWei(res.extractedTeamRewards, 'ether'));
  rewards.value.referRewards = Number(web3.utils.fromWei(res.referRewards, 'ether'));
  rewards.value.extractedReferRewards = Number(web3.utils.fromWei(res.extractedReferRewards, 'ether'));
  rewards.value.stakingRewards = Number(web3.utils.fromWei(res.stakingRewards, 'ether'));
  rewards.value.extractedStakingRewards = Number(web3.utils.fromWei(res.extractedStakingRewards, 'ether'));
  rewards.value.registrationRewards = Number(web3.utils.fromWei(res.registrationRewards, 'ether'));
}

const checkOwner = async () => {
  const owner = await stakingContractInstance.methods.owner().call();
  const user = localStorage.getItem('kepler.manage.wallet');
  ownerAddress.value = user;
  return web3.utils.toChecksumAddress(owner) === web3.utils.toChecksumAddress(user);
}

const searchLoading = ref(false);
const handleSearch = async () => {
  if (wallet.value === '') {
    showToast("请输入查询钱包地址");
    return;
  }
  if (searchType.value === -1) {
    showToast("请选择查询类型");
    return;
  }
  searchLoading.value = true;
  if (searchType.value === 0) {
    await getAccountInfo();
  } else if (searchType.value === 1) {
    await getRewardsInfo();
  }
  searchLoading.value = false;
}

onMounted(async () => {
  await initWeb3();
  await getSystemBaseInfo();
})
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;

    .sys-info {
      width: 100%;
      padding: 20px;
      background-color: #a1c2ff;
      border: 1px solid cornflowerblue;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .subtitle {
        color: white;
        font-size: 16px;
        font-weight: 400;
      }

      .info-form {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .account-info {
      width: 100%;
      padding: 20px;
      background-color: #a1c2ff;
      border: 1px solid cornflowerblue;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .subtitle {
        color: white;
        font-size: 16px;
        font-weight: 400;
      }

      .search-check {
        width: 100%;
        margin-bottom: 20px;
      }

      .search-wallet {
        width: 100%;
      }

      .account-cell {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>