import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    Button,
    Icon,
    Toast,
    Dialog,
    Field,
    CellGroup,
    Popup,
    Divider,
    Popover,
    Tabbar,
    TabbarItem,
    ConfigProvider,
    NavBar,
    List,
    Picker,
    Cell
} from 'vant';
import 'vant/lib/index.css';

createApp(App)
    .use(store)
    .use(router)
    .use(Button)
    .use(Icon)
    .use(Toast)
    .use(Dialog)
    .use(Field)
    .use(CellGroup)
    .use(Popup)
    .use(Divider)
    .use(Popover)
    .use(Tabbar)
    .use(TabbarItem)
    .use(ConfigProvider)
    .use(NavBar)
    .use(List)
    .use(Picker)
    .use(Cell)
    .mount('#app')
